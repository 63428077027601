interface forYouSirProps {
  isBg: boolean;
}

const ForYouSir = ({ isBg }: forYouSirProps) => {
  return (
    <section
      id="forYouSir"
      className={`hero hero__padding overflow-hidden position-relative ${isBg ? "bg-one" : ""
        }`}
    >
      <div className="circle x1"></div>
      <div className="circle x2"></div>
      <div className="circle x3"></div>
      <div className="circle x4"></div>
      <div className="circle x5"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7 col-lg-6 m-0px-b md-m-40px-b">
            <div className="hero__content position-relative">
              <h1 className="display-4 mb-4 text-capitalize">For You, Sir</h1>
              <p className="text-muted fs-5" style={{ fontWeight: "bold" }}>Coming this Fall!</p>
              <p className="text-muted fs-5"><b>For You, Sir</b> is a Contemporary MM Romance between a devoted service submissive butler and his pleasure-Dom employer.</p>
              <p className="text-muted fs-5">In this spicy stand-alone novel, loving D/s play builds trust, and loving support helps to heal the wounds of the past.</p>
              <div>
                <a
                  href="https://a.co/d/exNYUQr"
                  className="smooth button button__primary me-3"
                >
                  <span>Preorder Now</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-5 offset-lg-1 text-center">
            <div className="hero__images3">
              <a href="https://a.co/d/exNYUQr">
                <img
                  width="400"
                  className="img-fluid"
                  src="assets/images/forYouSir.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForYouSir;
