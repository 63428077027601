/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CtaV2 from "../ctav2";
import data from "../../data/footer.json";
import { Link } from "react-scroll";
import { siteLogo, socialLink } from "../../global";

const Footer = ({ isBg }: any) => {
  return (
    <>
      <footer
        className={`footer overflow-hidden ${isBg === "yes" ? "bg-one" : ""}`}
      >
        <div className="container">
          <hr />
          <div className="footer__copyright m-20px-t m-20px-b">
            <div className="row">
              <div className="col-12">
                <p className="m-0 text-center">
                  &copy; Emily Brandish 2023
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
    // <!-- ========== Footer section End ========== -->
  );
};

export default Footer;