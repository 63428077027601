import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/css/margins-paddings.css";
import Home from "./components/pages/home";

import AOS from "aos";
import { useEffect } from "react";
import "./assets/css/aos.css";

const mailerLiteKey:string = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYTllYmYxMWI3ZDAyMDhjNmYxMDJlY2VmZTRjYmI2YmUxMmY1NDk2ZGEzYjIxYjhkZjI0ZDZmYzZiM2ZkZDQyNTMwNWZlMDNhNjQ1OTU2Y2YiLCJpYXQiOjE2OTE5ODE4ODAuOTY4ODA2LCJuYmYiOjE2OTE5ODE4ODAuOTY4ODA4LCJleHAiOjQ4NDc2NTU0ODAuOTYzMzE2LCJzdWIiOiI1NTQzOTAiLCJzY29wZXMiOltdfQ.D-KjFmSOpBTzGXeVj4nn6d7psc48SDBHdtMss5dsr1axc9Sy8z29TR_CMA_abHHEjql8SpKe-Z9X5Tq7zKcDeVZ-Q1waWv_5Qdu9vHF79tUrAd1VT9qIXu8cSbg1bVb1fkUwz0-zClTILVS-s_fT0OoNTV1Dr3v8_bAsZaWRz_HpIrdMgAFm5-4wrgaABUXJmJeIEA-PhEyDZEcCjpO3osCqUDn-6UlR6X97uaG9wGAa1tTYsb2oqRxFDf6tCcZPv7yNusriZ1U8DX7R76YuHqBlZ43pmhOBT1iOCt3LcgCvsFd0HjUhRFg0ONCSnK3aoGKz-N4Ep1KATUacKRSkB6gFa_R8gtHeaCw7Q7-BktI0moO4Qv-T0aJuau2ig9GAe28fILtYSbJJBvykJ6ME_tQtoT6mqMpn9LPMG7CkqU_FqpW72jj3b-4N-iI8_kB1GiNcEqH1c24xyvbb9lJQuEy1WGkaE7IzqmcuN6YSDL5qY3yf4ME3Ei9Z2BNQfw8ed-ulaiqR9zALR_sIu45TidU-B6olg11IJTp1g44qkLWqn0TQ-PTCGaXud5dc8PXWOjUG95wtYqX_ec29EKAGNqotR7ka2waHZ7T0CFU6voBe4QClpVXUJuSNWws2yFZ9PTgs4DE_b4QTyX7EX6ckyV2kl5PfgYG3MAUIte-oxDo"

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="section-wrapper">
      <div id="preLoader"></div>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;