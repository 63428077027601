export const siteLogo = {
    logo: "assets/images/wonted-logo.png",  // Add your site logo here
    alt: "Emily Brandish"  // Add your site name here
};
export const socialLink = {
    facebook: "https://www.facebook.com/emilybrandish ",
    instagram: "https://www.instagram.com/emily_brandish/",
    goodReads: "https://www.goodreads.com/author/show/22110853.Emily_Brandish",
    amazon: "https://www.amazon.com/author/emilybrandish"
};
export const mailchimpLink = {
    link: (process.env.REACT_APP_MAILCHAIMP) // Add your mailchimp list link here
};
export const contactFormAction = {
    link: (process.env.REACT_APP_CONTACTFORM)  // Add for link here from https://formspree.io
}
export const contactMap = {
    link: (process.env.REACT_APP_CONTACTMAP) // Add google map link from here: https://www.embedgooglemap.net/
}
