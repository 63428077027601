interface monstersAndMindgamesProps {
  isBg: boolean;
}

const MonstersAndMindgames = ({ isBg }: monstersAndMindgamesProps) => {
  return (
    <section
      id="monstersAndMindGames"
      className={`hero hero__padding overflow-hidden position-relative ${isBg ? "bg-one" : ""
        }`}
    >
      <div className="circle x1"></div>
      <div className="circle x2"></div>
      <div className="circle x3"></div>
      <div className="circle x4"></div>
      <div className="circle x5"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7 col-lg-6 m-0px-b md-m-40px-b" style={{ textAlign: "center" }}>
            <div className="hero__images3">
              <a href="https://a.co/d/b6NG7zB">
                <img
                  width="400"
                  className="img-fluid"
                  src="assets/images/monstersAndMindgames.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="col-md-5 offset-lg-1 text-center" style={{ textAlign: "center" }}>
            <div className="hero__content position-relative">
              <h1 className="display-4 mb-4 text-capitalize">Monsters & Mind Games</h1>
              <p className="text-muted fs-5" style={{ fontWeight: "bold" }}>Coming August 15th!</p>
              <p className="text-muted fs-5">Monsters & Mind Games is an exciting MM fantasy adventure with complex characters, swoon-worthy romance, and a wickedly funny villain that will keep you guessing right up to the happily ever after.</p>
              <div>
                <a
                  href="https://a.co/d/b6NG7zB"
                  className="smooth button button__primary me-3"
                >
                  <span>Buy Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MonstersAndMindgames;
