import Author from "../author";
import Contact from "../contact";
import Ctav2 from "../ctav2";
import Footer from "../global/footer";
import Header from "../global/header";
import MonstersAndMindgames from "../books/monstersAndMindgames";
import ForYouSir from "../books/forYouSir";

const Home = () => {
  return (
    <>
      <Header />
      <MonstersAndMindgames isBg={true}/>
      <ForYouSir isBg={true}/>
      <Ctav2 isBg={false}/>
      <Author isBg={false} />
      {/* <Contact isBg={false}/> */}
      <Footer isBg={true}/>
    </>
  );
};

export default Home;
