import data from "../data/cta.json";

const Ctav2 = ({ isBg }: any) => {
  const { ctav2 } = data;
  return (
    // <!-- ========== CTA v2 section start ========== -->
    <section
      id="mailingList"
      className={`cta2 section-padding  ${isBg === "yes" ? "bg-one" : ""}`}
      style={{ backgroundImage: `url(${ctav2.backgroundImage})`, backgroundPosition:'center'  }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-3 offset-lg-1">
            <div className="hero__images3">
              <a href="https://dl.bookfunnel.com/r4w4usxj0d">
                <img
                  className="img-fluid"
                  src="assets/images/SnowyHoliday_small.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>

          <div className="col-md-7 col-lg-6 m-0px-b md-m-40px-b text-center">
            <div className="cta2__wrapper  position-relative">
              <h1 className="display-4 mb-4 text-capitalize">Snowy Holiday</h1>
              <p className="text-muted fs-5">A Cozy Bonus Epilogue for M&MG</p>
              <p className="text-muted fs-5">Get your FREE copy of Snowy Holiday</p>
              <div>
                <a
                  href="https://dl.bookfunnel.com/r4w4usxj0d"
                  className="smooth button button__primary me-3"
                >
                  <span>Get My Book</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== CTA v2 section end ========== -->
  );
};

export default Ctav2;
